import * as Types from '../../types';

import { gql } from '@apollo/client';
export type ProductGridFieldsFragment = {
  id: string;
  stock: number;
  value: { id: string; name: string; option: { id: string; name: string } };
  image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
};

export const ProductGridFieldsFragmentDoc = gql`
  fragment productGridFields on ProductGridType {
    id
    value {
      id
      option {
        id
        name
      }
      name
    }
    image {
      alt
      url(size: "80x80", crop: "center center")
      avif: url(size: "80x80", crop: "center center", format: AVIF)
      webp: url(size: "80x80", crop: "center center", format: WEBP)
    }
    stock
  }
`;
