import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BoxesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type BoxesQuery = {
  boxes?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        title: string;
        subtitle: string;
        month: Date;
        images: Array<{
          file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        }>;
      } | null;
    } | null>;
  } | null;
};

export type BoxesDetailsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
}>;

export type BoxesDetailsQuery = {
  boxes?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        title: string;
        month: Date;
        products: Array<{
          id: string;
          product: {
            id: string;
            score?: number | null;
            characteristics?: string | null;
            coffeeKind?: Types.ProductCoffeeKind | null;
            name: string;
            region?: { id: string; name: string; stateCode: string } | null;
            variety?: { id: string; name: string } | null;
            producer?: { id: string; name: string; farm: string } | null;
          };
        }>;
      } | null;
    } | null>;
  } | null;
};

export const BoxesDocument = gql`
  query Boxes($first: Int = 100, $orderBy: [String] = ["-date_from"]) {
    boxes(first: $first, orderby: $orderBy) {
      edges {
        node {
          id
          title
          subtitle
          month
          images {
            file {
              alt
              url(size: "260")
              avif: url(size: "260", format: AVIF)
              webp: url(size: "260", format: WEBP)
            }
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useBoxesQuery__
 *
 * To run a query within a React component, call `useBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoxesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBoxesQuery(
  baseOptions?: Apollo.QueryHookOptions<BoxesQuery, BoxesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoxesQuery, BoxesQueryVariables>(BoxesDocument, options);
}
export function useBoxesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BoxesQuery, BoxesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoxesQuery, BoxesQueryVariables>(BoxesDocument, options);
}
export type BoxesQueryHookResult = ReturnType<typeof useBoxesQuery>;
export type BoxesLazyQueryHookResult = ReturnType<typeof useBoxesLazyQuery>;
export type BoxesQueryResult = Apollo.QueryResult<BoxesQuery, BoxesQueryVariables>;
export const BoxesDetailsDocument = gql`
  query BoxesDetails($first: Int = 100, $orderBy: [String] = ["-date_from"]) {
    boxes(first: $first, orderby: $orderBy) {
      totalCount
      edges {
        node {
          id
          title
          month
          products {
            id
            product {
              id
              region {
                id
                name
                stateCode
              }
              score
              characteristics
              variety {
                id
                name
              }
              producer {
                id
                name
                farm
              }
              coffeeKind
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useBoxesDetailsQuery__
 *
 * To run a query within a React component, call `useBoxesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoxesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoxesDetailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBoxesDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<BoxesDetailsQuery, BoxesDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoxesDetailsQuery, BoxesDetailsQueryVariables>(
    BoxesDetailsDocument,
    options,
  );
}
export function useBoxesDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BoxesDetailsQuery, BoxesDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoxesDetailsQuery, BoxesDetailsQueryVariables>(
    BoxesDetailsDocument,
    options,
  );
}
export type BoxesDetailsQueryHookResult = ReturnType<typeof useBoxesDetailsQuery>;
export type BoxesDetailsLazyQueryHookResult = ReturnType<typeof useBoxesDetailsLazyQuery>;
export type BoxesDetailsQueryResult = Apollo.QueryResult<
  BoxesDetailsQuery,
  BoxesDetailsQueryVariables
>;
