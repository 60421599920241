import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestimonialFieldsFragment = {
  title: string;
  subtitle?: string | null;
  text: string;
  id: string;
  image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
};

export type TestimonialsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TestimonialsQuery = {
  testimonials?: Array<{
    title: string;
    subtitle?: string | null;
    text: string;
    id: string;
    image?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  }> | null;
};

export const TestimonialFieldsFragmentDoc = gql`
  fragment testimonialFields on TestimonialType {
    title
    subtitle
    text
    id
    image {
      alt
      url(size: "40x40")
      avif: url(size: "40x40", format: AVIF)
      webp: url(size: "40x40", format: WEBP)
    }
  }
`;
export const TestimonialsDocument = gql`
  query Testimonials {
    testimonials {
      ...testimonialFields
    }
  }
  ${TestimonialFieldsFragmentDoc}
`;

/**
 * __useTestimonialsQuery__
 *
 * To run a query within a React component, call `useTestimonialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestimonialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestimonialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestimonialsQuery(
  baseOptions?: Apollo.QueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestimonialsQuery, TestimonialsQueryVariables>(
    TestimonialsDocument,
    options,
  );
}
export function useTestimonialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestimonialsQuery, TestimonialsQueryVariables>(
    TestimonialsDocument,
    options,
  );
}
export type TestimonialsQueryHookResult = ReturnType<typeof useTestimonialsQuery>;
export type TestimonialsLazyQueryHookResult = ReturnType<typeof useTestimonialsLazyQuery>;
export type TestimonialsQueryResult = Apollo.QueryResult<
  TestimonialsQuery,
  TestimonialsQueryVariables
>;
