import * as Types from '../../types';

import { gql } from '@apollo/client';
import { ProductListFieldsFragmentDoc } from './products';
import { ProductDetailsFragmentDoc } from '../../fragments/_gen/product-details-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductGridOptionsFragment = {
  id: string;
  stock: number;
  value: { id: string; name: string; option: { id: string; name: string } };
  image?: { url: string; alt?: string | null } | null;
};

export type ProductFieldsFragment = {
  quantityStr?: string | null;
  descriptionHtml: string;
  id: string;
  name: string;
  slug: string;
  price: number;
  originalPrice?: number | null;
  isFresh: boolean;
  isFeatured: boolean;
  isOnSale: boolean;
  onSaleDiscount?: number | null;
  clubPrice: number;
  stock: number;
  coffeeKind?: Types.ProductCoffeeKind | null;
  kind: Types.ProductKind;
  quantity: number;
  altitude?: number | null;
  characteristics?: string | null;
  score?: number | null;
  olfactory?: string | null;
  visual?: string | null;
  taste?: string | null;
  unit: Types.ProductUnit;
  metaTitle: string;
  metaDescription: string;
  scaReportUrl?: string | null;
  width?: number | null;
  height?: number | null;
  depth?: number | null;
  weight?: number | null;
  foodPairing?: string | null;
  isSoldOut?: boolean | null;
  grids: Array<{
    id: string;
    stock: number;
    value: { id: string; name: string; option: { id: string; name: string } };
    image?: { url: string; alt?: string | null } | null;
  }>;
  region?: {
    id: string;
    name: string;
    stateCode: string;
    slug: string;
    state: string;
    image?: { url: string; alt?: string | null } | null;
  } | null;
  feedbacks: Array<{
    id: string;
    name: string;
    comment: string;
    subtitle?: string | null;
    avatar?: { url: string; alt?: string | null } | null;
  }>;
  producer?: {
    id: string;
    name: string;
    farm: string;
    slug: string;
    descriptionHtml: string;
    video?: string | null;
    region: { name: string; state: string; stateCode: string };
    images: Array<{
      id: string;
      file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      original?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      thumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    }>;
    videoThumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    avatar?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  } | null;
  variety?: {
    id: string;
    name: string;
    slug: string;
    image?: { url: string; alt?: string | null } | null;
  } | null;
  images: Array<{
    id: string;
    file?: { url: string; alt?: string | null; avif: string; webp: string } | null;
    original?: { url: string; alt?: string | null } | null;
    thumbnail?: { url: string; alt?: string | null; avif: string; webp: string } | null;
  }>;
};

export type ProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ProductQuery = {
  product?: {
    quantityStr?: string | null;
    descriptionHtml: string;
    id: string;
    name: string;
    slug: string;
    price: number;
    originalPrice?: number | null;
    isFresh: boolean;
    isFeatured: boolean;
    isOnSale: boolean;
    onSaleDiscount?: number | null;
    clubPrice: number;
    stock: number;
    coffeeKind?: Types.ProductCoffeeKind | null;
    kind: Types.ProductKind;
    quantity: number;
    altitude?: number | null;
    characteristics?: string | null;
    score?: number | null;
    olfactory?: string | null;
    visual?: string | null;
    taste?: string | null;
    unit: Types.ProductUnit;
    metaTitle: string;
    metaDescription: string;
    scaReportUrl?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    weight?: number | null;
    foodPairing?: string | null;
    isSoldOut?: boolean | null;
    grids: Array<{
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    }>;
    region?: {
      id: string;
      name: string;
      stateCode: string;
      slug: string;
      state: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    feedbacks: Array<{
      id: string;
      name: string;
      comment: string;
      subtitle?: string | null;
      avatar?: { url: string; alt?: string | null } | null;
    }>;
    producer?: {
      id: string;
      name: string;
      farm: string;
      slug: string;
      descriptionHtml: string;
      video?: string | null;
      region: { name: string; state: string; stateCode: string };
      images: Array<{
        id: string;
        file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        original?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        thumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      }>;
      videoThumbnail?: { alt?: string | null; url: string; avif: string; webp: string } | null;
      avatar?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    } | null;
    variety?: {
      id: string;
      name: string;
      slug: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    images: Array<{
      id: string;
      file?: { url: string; alt?: string | null; avif: string; webp: string } | null;
      original?: { url: string; alt?: string | null } | null;
      thumbnail?: { url: string; alt?: string | null; avif: string; webp: string } | null;
    }>;
  } | null;
};

export const ProductGridOptionsFragmentDoc = gql`
  fragment productGridOptions on ProductGridType {
    id
    value {
      id
      option {
        id
        name
      }
      name
    }
    image {
      url
      alt
    }
    stock
  }
`;
export const ProductFieldsFragmentDoc = gql`
  fragment productFields on ProductType {
    ...productListFields
    ...productDetails
    quantityStr
    descriptionHtml
    grids {
      ...productGridOptions
    }
  }
  ${ProductListFieldsFragmentDoc}
  ${ProductDetailsFragmentDoc}
  ${ProductGridOptionsFragmentDoc}
`;
export const ProductDocument = gql`
  query Product($id: ID!) {
    product(id: $id) {
      ...productFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
