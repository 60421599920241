import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductListImagesFieldsFragment = {
  images: Array<{
    id: string;
    file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  }>;
};

export type ProductListFieldsFragment = {
  id: string;
  name: string;
  slug: string;
  price: number;
  originalPrice?: number | null;
  isFresh: boolean;
  isFeatured: boolean;
  isOnSale: boolean;
  onSaleDiscount?: number | null;
  clubPrice: number;
  stock: number;
  coffeeKind?: Types.ProductCoffeeKind | null;
  kind: Types.ProductKind;
  region?: { id: string; name: string; stateCode: string } | null;
};

export type ProductsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  kinds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.ProductKind>> | Types.InputMaybe<Types.ProductKind>
  >;
  coffeeKinds?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.ProductCoffeeKind>> | Types.InputMaybe<Types.ProductCoffeeKind>
  >;
  orderby?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>
  >;
  region?: Types.InputMaybe<Types.Scalars['ID']>;
  variety?: Types.InputMaybe<Types.Scalars['ID']>;
  producer?: Types.InputMaybe<Types.Scalars['ID']>;
  finality: Types.FinalityKind;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  gridOptionValue?: Types.InputMaybe<Types.Scalars['ID']>;
  isOnSale?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type ProductsQuery = {
  products?: {
    totalCount?: number | null;
    edges: Array<{
      node?: {
        id: string;
        name: string;
        slug: string;
        price: number;
        originalPrice?: number | null;
        isFresh: boolean;
        isFeatured: boolean;
        isOnSale: boolean;
        onSaleDiscount?: number | null;
        clubPrice: number;
        stock: number;
        coffeeKind?: Types.ProductCoffeeKind | null;
        kind: Types.ProductKind;
        region?: { id: string; name: string; stateCode: string } | null;
        images: Array<{
          id: string;
          file?: { alt?: string | null; url: string; avif: string; webp: string } | null;
        }>;
      } | null;
    } | null>;
  } | null;
};

export const ProductListImagesFieldsFragmentDoc = gql`
  fragment productListImagesFields on ProductType {
    images {
      id
      file {
        alt
        url(size: "300x300", crop: "center center")
        avif: url(size: "300x300", crop: "center center", format: AVIF)
        webp: url(size: "300x300", crop: "center center", format: WEBP)
      }
    }
  }
`;
export const ProductListFieldsFragmentDoc = gql`
  fragment productListFields on ProductType {
    id
    name
    slug
    price
    originalPrice
    isFresh
    isFeatured
    isOnSale
    onSaleDiscount
    clubPrice
    stock
    coffeeKind
    kind
    region {
      id
      name
      stateCode
    }
  }
`;
export const ProductsDocument = gql`
  query Products(
    $after: String
    $first: Int
    $last: Int
    $kinds: [ProductKind]
    $coffeeKinds: [ProductCoffeeKind]
    $orderby: [String]
    $region: ID
    $variety: ID
    $producer: ID
    $finality: FinalityKind!
    $search: String
    $gridOptionValue: ID
    $isOnSale: Boolean
  ) {
    products(
      after: $after
      first: $first
      last: $last
      kinds: $kinds
      coffeeKinds: $coffeeKinds
      orderby: $orderby
      region: $region
      variety: $variety
      producer: $producer
      finality: $finality
      search: $search
      gridOptionValue: $gridOptionValue
      isOnSale: $isOnSale
    ) {
      totalCount
      edges {
        node {
          ...productListFields
          ...productListImagesFields
        }
      }
    }
  }
  ${ProductListFieldsFragmentDoc}
  ${ProductListImagesFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      kinds: // value for 'kinds'
 *      coffeeKinds: // value for 'coffeeKinds'
 *      orderby: // value for 'orderby'
 *      region: // value for 'region'
 *      variety: // value for 'variety'
 *      producer: // value for 'producer'
 *      finality: // value for 'finality'
 *      search: // value for 'search'
 *      gridOptionValue: // value for 'gridOptionValue'
 *      isOnSale: // value for 'isOnSale'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
