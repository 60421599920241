import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BannerFieldsFragment = {
  id: string;
  kind: Types.BannerKind;
  title1?: string | null;
  title2?: string | null;
  subtitle1?: string | null;
  subtitle2?: string | null;
  ctaLabel?: string | null;
  ctaAction?: string | null;
  bannerDesktop?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  bannerMobile?: { alt?: string | null; url: string; avif: string; webp: string } | null;
};

export type BannersQueryVariables = Types.Exact<{
  kind?: Types.InputMaybe<Types.BannerKind>;
}>;

export type BannersQuery = {
  banners: Array<{
    id: string;
    kind: Types.BannerKind;
    title1?: string | null;
    title2?: string | null;
    subtitle1?: string | null;
    subtitle2?: string | null;
    ctaLabel?: string | null;
    ctaAction?: string | null;
    bannerDesktop?: { alt?: string | null; url: string; avif: string; webp: string } | null;
    bannerMobile?: { alt?: string | null; url: string; avif: string; webp: string } | null;
  }>;
};

export const BannerFieldsFragmentDoc = gql`
  fragment bannerFields on BannerType {
    id
    kind
    title1
    title2
    subtitle1
    subtitle2
    ctaLabel
    ctaAction
    bannerDesktop {
      alt
      url(size: "1232x528")
      avif: url(size: "1232x528", format: AVIF)
      webp: url(size: "1232x528", format: WEBP)
    }
    bannerMobile {
      alt
      url(size: "552x552")
      avif: url(size: "552x552", format: AVIF)
      webp: url(size: "552x552", format: WEBP)
    }
  }
`;
export const BannersDocument = gql`
  query Banners($kind: BannerKind) {
    banners(kind: $kind) {
      ...bannerFields
    }
  }
  ${BannerFieldsFragmentDoc}
`;

/**
 * __useBannersQuery__
 *
 * To run a query within a React component, call `useBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannersQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useBannersQuery(
  baseOptions?: Apollo.QueryHookOptions<BannersQuery, BannersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BannersQuery, BannersQueryVariables>(BannersDocument, options);
}
export function useBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BannersQuery, BannersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BannersQuery, BannersQueryVariables>(BannersDocument, options);
}
export type BannersQueryHookResult = ReturnType<typeof useBannersQuery>;
export type BannersLazyQueryHookResult = ReturnType<typeof useBannersLazyQuery>;
export type BannersQueryResult = Apollo.QueryResult<BannersQuery, BannersQueryVariables>;
